import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { checkPerms, checkFeatures } from '../helpers/utils';
import NotificationsBlock from './NotificationsBlock';
import { ReactComponent as RightIcon } from '../assets/images/arrow-right.svg';
import { ReactComponent as LeftIcon } from '../assets/images/arrow-left.svg';

const toggleSidebar = () => {
  if (document.getElementById('root').classList.value.includes('sidebar-mobile-main')) {
    document.getElementById('sidebar-links-hide').click();
    document.getElementById('sidebar').classList.remove('sidebar-fullscreen');
  }
  document.getElementById('root').classList.toggle('sidebar-mobile-main');
};

const SidebarMobileToogler = () => (
  <div className="sidebar-mobile-toggler text-center">
    <button type="button" className="sidebar-mobile-main-toggle" onClick={() => toggleSidebar()}>
      <i className="icon-arrow-left8" />
    </button>
    Navigation
    <button
      className="sidebar-mobile-expand"
      type="button"
      onClick={() => document.getElementById('sidebar').classList.toggle('sidebar-fullscreen')}
    >
      <i className="icon-screen-full" />
      <i className="icon-screen-normal" />
    </button>
  </div>
);

const SidebarNavItem = ({ name, link, iconClass }) => {
  const click = () => {
    toggleSidebar();
  };
  return (
    <li className="nav-item">
      <NavLink to={link} onClick={click} className="nav-link" activeClassName="active">
        {iconClass ? <i className={iconClass} /> : ''}
        <span>{name}</span>
      </NavLink>
    </li>
  );
};

SidebarNavItem.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  iconClass: PropTypes.string
};

SidebarNavItem.defaultProps = {
  iconClass: ''
};

// To Do: Need to make sidebar fixed
const SidebarNavItemSubMenu = ({ iconClass, name, children }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <li className="nav-item nav-item-submenu">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`nav-link ${isOpen ? 'nav-item-open' : ''}`}
        type="button"
      >
        {iconClass.length > 0 ? <i className={iconClass} /> : ''}
        <span>{name}</span>
      </button>
      <ul className={`nav nav-group-sub ${isOpen ? 'display-block' : ''}`}>{children}</ul>
    </li>
  );
};

SidebarNavItemSubMenu.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  iconClass: PropTypes.string
};

SidebarNavItemSubMenu.defaultProps = {
  iconClass: ''
};

const NavbarNav = () => {
  const [open, setOpen] = useState();
  const toggle = () => {
    document.body.classList.toggle('sidebar-xs');
    setOpen(!open);
  };
  return (
    <li className="nav-item">
      <button
        type="button"
        onClick={toggle}
        // className="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block"
        className="nav-link pb-0 main-icon d-flex"
      >
        {open ? (
          <LeftIcon className="sidebar-toggle-icon justify-self-center" />
        ) : (
          <RightIcon className="sidebar-toggle-icon" />
        )}
      </button>
      <div className="sidebar-devider" />
    </li>
  );
};

const sideBar = () => (
  <div
    className="sidebar sidebar-light sidebar-main sidebar-expand-md align-self-start protean-sidebar"
    style={{ zIndex: '96' }}
    id="sidebar"
  >
    <SidebarMobileToogler />
    <NotificationsBlock />

    <div className="sidebar-content" id="sidebar-links">
      <div className="card card-sidebar-mobile">
        <ul className="nav nav-sidebar" data-nav-type="accordion">
          <NavbarNav />
          {/* <li className="nav-item-header">
            <div className="text-uppercase font-size-xs line-height-xs">Menu</div>
            <i className="icon-menu" title="Menu" />
          </li> */}
          {/* {checkPerms('dashboard') && (
            <SidebarNavItem name="Dashboard" link="/dashboard" iconClass="mi-home" />
          )} */}
          {checkFeatures('atg.enabled') && (
            <SidebarNavItemSubMenu name="Remote Monitoring" iconClass="mi-settings">
              <SidebarNavItem name="Release Detection" link="/release_detection" />
              <SidebarNavItem name="Command Center" link="/command_center" />
              <SidebarNavItem name="Connection Center" link="/connection_center" />
              {checkFeatures('atg.setup_center.access') && (
                <SidebarNavItem name="Setup Center" link="/setup_center" />
              )}
              <SidebarNavItem name="ATG Alarms" link="/alarms" />
              {/* <SidebarNavItem name="Line Item Overview" link="/lineitems" /> */}
            </SidebarNavItemSubMenu>
          )}
          {checkPerms('workorders_enabled') && (
            <SidebarNavItemSubMenu name="Work Orders" iconClass="mi-list">
              <SidebarNavItem name="Work Order Overview" link="/workorders" />
              {/* <SidebarNavItem name="Line Item Overview" link="/lineitems" /> */}
            </SidebarNavItemSubMenu>
          )}
          {/* <SidebarNavItem name="Issues" link="/issues" iconClass="mi-find-in-page" /> */}
          {/* <SidebarNavItem name="Inspections" link="/reports/all" iconClass="mi-folder-open" /> */}
          {checkPerms('file_cabinet_enabled') && (
            <SidebarNavItem name="eCabinet" link="/e-cabinet" iconClass="mi-account-circle" />
          )}
          {/* testing only for client level/admin/superadmin */}
          {/* <SidebarNavItem name="Test Tracking" link="/tests" iconClass="mi-playlist-add-check" /> */}
          {/* permits only  client level/admin/superamin */}
          {/* <SidebarNavItem name="Permits" link="/permits" iconClass="mi-lock-open" /> */}
          {checkPerms('reports_enabled') && (
            <SidebarNavItemSubMenu name="Reports" iconClass="mi-event-note">
              <SidebarNavItem name="Analytics Report" link="/reports/analytics_report" />
              <SidebarNavItem name="Recap Report" link="/reports/recap" />
              <SidebarNavItem name="Remedy Report" link="/reports/remedy" />
              <SidebarNavItem name="Regulator Report" link="/reports/regulator" />
              <SidebarNavItem name="Hot Topic Report" link="/reports/hot_topic" />
              <SidebarNavItem
                name="Cathodic Protection Report"
                link="/reports/cathodic_protection"
              />
              <SidebarNavItem name="Widgets Report" link="/reports/widgets" />
              <SidebarNavItem
                name="Monthly Comparison Report"
                link="/reports/monthly_comparison_report"
              />
              {checkPerms('daily_overview_enabled') && (
                <SidebarNavItem name="Daily Overview Report" link="/reports/daily_overview" />
              )}
            </SidebarNavItemSubMenu>
          )}
          {/* {checkPerms('analytics_enabled') && (
            <SidebarNavItem name="Analytics" link="/analytics" iconClass="mi-equalizer" />
          )} */}
          {/* agencies only admin/superamin */}
          {/* <SidebarNavItem name="Agencies" link="/agencies" iconClass="mi-supervisor-account" /> */}
          {/* contractors only admin/superamin */}
          {/* <SidebarNavItem name="Contractors" link="/contractors" iconClass="mi-business-center" /> */}
          {/* atg only admin/superamin black page not implemented */}
          {/* <SidebarNavItem name="ATG testbed" link="/atg-test-bed" iconClass="mi-description" /> */}
        </ul>
      </div>
    </div>
  </div>
);

const SideBar = React.memo(sideBar);
export default SideBar;
