import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer, cssTransition } from 'react-toastify';
import { Route, Router, Switch } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import history from './helpers/history';
import ProtectedRoute from './components/ProtectedRoute';
import { PageProvider } from './layout/pageContext';
// import {
 //  Login,
 //  Logout,
 //  ForgotPassword,
 //  ResetPassword,
 //  Register,
 //  Invitation,
 //  Files,
 //  PageNotFound,
 //  ShowImage,
 //  ReleaseDetection,
 //  PageNotFoundUnauthorise,
 //  QRCode,
 //  CommandCenter,
 //  TermsOfService,
 //  ShowPdf,
 //  WorkOrders,
 //  Issues,
 //  Group,
 //  Recap,
 //  Remedy,
 //  Regulator,
 //  HotTopic,
 //  CathodicProtection,
 //  WidgetsReport,
 //  ComparisonReport,
 //  DailyOverview,
 //  AnalyticsReport,
 //  SingleWorkOrder,
 //  SharedFiles,
 //  SetupCenter,
 // ATGAlarms
// } from './pages';
import HomeRoute from './components/HomeRoute';
import ConnectionCenter from './pages/connectionCenter';
import CommingSoon from './pages/base/commingSoon';

const Login = React.lazy(() => import('./pages/authentication/login'));
const Logout = React.lazy(() => import('./pages/authentication/logout'));
const ForgotPassword = React.lazy(() => import('./pages/authentication/forgotPassword'));
const ResetPassword = React.lazy(() => import('./pages/authentication/ResetPassword'));
const Register = React.lazy(() => import('./pages/authentication/registration'));
const Invitation = React.lazy(() => import('./pages/authentication/Invitation'));
const Files = React.lazy(() => import('./pages/files'));
const PageNotFound = React.lazy(() => import('./pages/base/404'));
const ShowImage = React.lazy(() => import('./pages/base/ShowImage'));
const ReleaseDetection = React.lazy(() => import('./pages/releaseDetection'));

const PageNotFoundUnauthorise = React.lazy(() => import('./pages/base/404').then(module => ({ default: module.PageNotFoundUnauthorise })));

const QRCode = React.lazy(() => import('./pages/QRCode'));
const CommandCenter = React.lazy(() => import('./pages/commandCenter'));
const TermsOfService = React.lazy(() => import('./pages/terms'));
const ShowPdf = React.lazy(() => import('./pages/base/ShowPdf'));
const WorkOrders = React.lazy(() => import('./pages/workorders'));
const Issues = React.lazy(() => import('./pages/issue'));
const Group = React.lazy(() => import('./pages/issue/group'));
const Recap = React.lazy(() => import('./pages/reports/Recap'));
const Remedy = React.lazy(() => import('./pages/reports/Remedy'));
const Regulator = React.lazy(() => import('./pages/reports//Regulator'));
const HotTopic = React.lazy(() => import('./pages/reports/HotTopic'));
const CathodicProtection = React.lazy(() => import('./pages/reports/CathodicProtection'));
const WidgetsReport = React.lazy(() => import('./pages/reports/WidgetsReport'));
const ComparisonReport = React.lazy(() => import('./pages/reports/ComparisonReport'));
const DailyOverview = React.lazy(() => import('./pages/reports/DailyOverview'));
const AnalyticsReport = React.lazy(() => import('./pages/reports/AnalyticsReport'));
const SingleWorkOrder = React.lazy(() => import('./pages/singleWorkOrder'));
const SharedFiles = React.lazy(() => import('./pages/sharedFiles'));
const SetupCenter = React.lazy(() => import('./pages/setupCenter'));
const ATGAlarms = React.lazy(() => import('./pages/alarms'));

if (!window.origin.includes('local')) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN_MOBILE,
    integrations: [
      new Sentry.BrowserTracing({
        tracingOrigins: ['*'],
      }),
      new Sentry.Replay()
    ],
    environment: process.env.REACT_APP_SENTRY_ENV || 'NoEnv',
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const Fallback = () => (
    <div class="preloader-container">
      <div class="preloader">
        <span class="preloader-inner">Loading...</span>
      </div>
    </div>
)

const App = () => {
  return (
    <Router history={history}>
      <Suspense fallback={Fallback}>
      <Switch>
        <Route exact path="/" >
          <HomeRoute />
        </Route>
        <ProtectedRoute exact visible="unauthorized" path="/login" component={Login} name="login" />
        <ProtectedRoute exact visible="unauthorized" path="/forgot-password" component={ForgotPassword} name="forgot_password" />
        <ProtectedRoute exact visible="unauthorized" path="/reset-password/:token" component={ResetPassword} name="reset_password" />
        <ProtectedRoute exact visible="protected" path="/logout" component={Logout} name="logout" />
        <ProtectedRoute exact visible="unauthorized" path="/self-registration/:token" component={Register} name="register" />
        <ProtectedRoute exact visible="protected" path="/reports/analytics_report" component={AnalyticsReport} name="reports_enabled" />
        <ProtectedRoute exact visible="protected" path="/reports/daily_overview" component={DailyOverview} name="daily_overview_enabled"/>
        <ProtectedRoute exact visible="protected" path="/reports/recap" component={Recap} name="reports_enabled" />
        <ProtectedRoute exact visible="protected" path="/reports/remedy" component={Remedy} name="reports_enabled" />
        <ProtectedRoute exact visible="protected" path="/reports/regulator" component={Regulator} name="reports_enabled" />
        <ProtectedRoute exact visible="protected" path="/reports/hot_topic" component={HotTopic} name="reports_enabled" />
        <ProtectedRoute exact visible="protected" path="/reports/cathodic_protection" component={CathodicProtection} name="reports_enabled" />
        <ProtectedRoute exact visible="protected" path="/reports/widgets" component={WidgetsReport} name="reports_enabled" />
        <ProtectedRoute exact visible="protected" path="/reports/monthly_comparison_report" component={ComparisonReport} name="reports_enabled" />
        {/*<ProtectedRoute exact visible="protected" path="/reports/compliance_scorecard" component={WalmartScorecard} name="reports_enabled" />*/}
        <ProtectedRoute exact visible="protected" path="/release_detection" component={ReleaseDetection} name="atg" />
        <ProtectedRoute exact visible="protected" path="/command_center" component={CommandCenter} name="atg" />
        <ProtectedRoute exact visible="protected" path="/connection_center" component={ConnectionCenter} name="atg" />
        <ProtectedRoute exact visible="protected" path="/setup_center" component={SetupCenter} name="atg" />
        <ProtectedRoute exact visible="protected" path="/alarms" component={ATGAlarms} name="atg" feature="atg.enabled" />
        <ProtectedRoute exact visible="protected" path="/workorders" component={WorkOrders} name="workorders_enabled" />
        <ProtectedRoute exact visible="protected" path="/workorders/:id" component={SingleWorkOrder} name="workorders_enabled" detail />
        <ProtectedRoute exact visible="protected" path="/issues/:id" component={Issues} name="workorders_enabled" />
        <ProtectedRoute exact visible="protected" path="/groups/:id" component={Group} name="workorders_enabled" />
        <ProtectedRoute exact visible="protected" path="/e-cabinet" component={Files} name="file_cabinet_enabled" />
        <ProtectedRoute exact visible="protected" path="/e-cabinet/id/:fileId" component={Files} name="file_cabinet_enabled" />
        <ProtectedRoute exact visible="protected" path="/e-cabinet/search/:query" component={Files} name="file_cabinet_enabled" />
        <ProtectedRoute exact visible="protected" path="/image/:imageId" component={ShowImage} name="show_image" />
        <Route exact visible="protected" path="/pdf/:pdfId" component={ShowPdf} name="show_pdf" />
        <Route exact path="/e-cabinet/share/:fileId/:subId" component={SharedFiles} name="share" />
        <Route exact path="/e-cabinet/share/:fileId" component={SharedFiles} name="share" />
        <Route exact visible="unauthorized" path="/qr/:uuid" component={QRCode} name="login" />
        <Route exact path="/terms_of_service" component={TermsOfService} name="terms_service" />
        <ProtectedRoute exact visible="protected" path="/facility_profile" component={CommingSoon} name="facility_profile" />
        <ProtectedRoute exact visible="protected" path="/issues" component={CommingSoon} name="issues" />
        <ProtectedRoute exact visible="unauthorized" path="/invitation/:uuid" component={Invitation} name="login" />
        <ProtectedRoute exact visible="protected" path="/404" component={PageNotFound} name="404" />
       
        <Route component={PageNotFoundUnauthorise} />
      </Switch>
      </Suspense>
    </Router>
  );
};

const Zoom = cssTransition({
  enter: 'zoomIn',
  exit: 'zoomOut'
});

ReactDOM.render(
  (
    <PageProvider>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Zoom}
      />
        <App />
    </PageProvider>
  ),
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
