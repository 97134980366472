// import regression from 'regression';
// import { read, write } from 'xlsx';
import moment from 'moment';
import { read, write } from 'xlsx';
import axios from '../_actions/axiosInstance';
import { getUserData } from '../_actions/user.actions';

export const extractSetupCommand = command => {
  if (!command) return '';
  const paramsStartIndex = command.indexOf('{');
  return command.slice(1, paramsStartIndex).padEnd(5, '0');
};

export const EmailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const processEmailStringToArray = emailString => {
  const emailArray = emailString
    .trim()
    .replace(/ /g, '')
    .replace(/(\r\n|\n|\r)/gm, '')
    .split(',')
    .filter(e => e !== '');
  return emailArray;
};
// export const DefaultDateFormat = (val, initFormat, format) => {
//   const date = moment(val, initFormat);
//   if (date.isValid()) {
//     return date.format(format);
//   }
//   return '';
// };

export const stringReplaceAll = (str, sch, rpl) => {
  let rsp = str;
  while (rsp.indexOf(sch) > 0) {
    rsp = rsp.replace(sch, rpl);
  }
  return rsp;
};

export const processBarChartData = data => {
  const arr = [];
  data.columns.forEach((x, index) => {
    if (!['Total', ''].includes(x[1])) {
      arr.push({
        value: data.data[0][index],
        label: x[1]
      });
    }
  });
  return arr;
};

export const processLineChartData = rData => {
  // rawData looks like {'total': {'index':[.,..,.], 'columns':[..,.,..], 'data':[..,.,..]},
  //                     'recurring': {'index':[.,..,.], 'columns':[..,.,..], 'data':[..,.,..]},
  //                     'repeating': {'index':[.,..,.], 'columns':[..,.,..], 'data':[..,.,..]}
  //                     }
  // There may also be a situation where recurring or repeating is missing
  const colonedObj = { ...rData };
  const rawData = Object.values(colonedObj);
  const data = [];
  const totalCounts = { total: 0, recurring: 0, repeating: 0 };
  // Get all unique labels(months) for all datasets
  const labels = new Set(
    rawData
      .map(d => d.columns.map(c => c[1]))
      .flat(1)
      .filter(x => x !== 'Total' && x !== '')
      .sort()
  );
  Object.keys(rData).forEach(key => {
    let label = '';
    let borderColor = '';

    if (key === 'total') {
      label = 'Deficiencies';
      borderColor = 'rgba(9, 123, 199, 1)';
    }
    if (key === 'recurring') {
      label = 'Recurring';
      borderColor = 'rgba(255, 87, 34, 1)';
    }
    if (key === 'repeating') {
      label = 'Repeating';
      borderColor = 'rgba(76, 175, 80, 1)';
    }
    const d = rData[key];
    const arr = [];
    // Find 'Total' data array index.(There may be a situation where the data array may not be with the first index)
    const totalsDataArrayIndex = d.index.findIndex(function(item) {
      return item === 'Total';
    });
    // If for any month there are no issues, Panda does not include this month in the dataset
    // which it sends from the backend. Therefore, there may be a situation(for example) that
    // Repeating Issues have data for [02,03,04,05,06,07] months and
    // Recurring Issues only for [04,05,06,07]. Chats.js generates points in the chart sequentially,
    // regardless of the month. Thus, the graphs will be displayed incorrectly.
    // Therefore, we do a comparison of all labels with columns that are in the dataset,
    // and if the month is missing in the specific dataset, we substitute 'null' for this month(in returning new dataset).
    labels.forEach(l => {
      let val = null;
      d.columns.forEach((x, index) => {
        if (x[1] !== 'Total' && x[0] !== 'AVG/MO') {
          if (x[1] === l) {
            val = d.data[totalsDataArrayIndex][index];
          }
        }
        if (x[1] === 'Total') {
          // set total count of issues by key.(For example - 'recurring : 1520')
          totalCounts[key] = d.data[totalsDataArrayIndex][index];
        }
      });
      if (val) {
        arr.push(val);
      } else {
        // if no columns with a specific month were found in the dataset, we substitute 'null' for this month.
        arr.push(null);
      }
    });
    data.push({
      data: arr,
      label,
      borderColor,
      pointBackgroundColor: 'rgba(255,255,255, 1)',
      fill: 'false',
      borderWidth: 2,
      pointRadius: 4
    });
  });
  return { data, labels: new Array(...labels), totalCounts };
};

export const processBarChartCategoryData = data => {
  const arr = [];
  data.index.forEach((x, index) => {
    if (!['Total', ''].includes(x)) {
      arr.push({
        value: Math.ceil(data.data[index].slice(-1)[0]),
        label: x
      });
    }
  });
  return arr;
};

// const ExponentialMovingAvg = (input, range) => {
//   const k = 2 / (range + 1);
//   const ema = [input[0]];
//   for (let i = 1; i < input.length; i++) {
//     ema.push(input[i] * k + ema[i - 1] * (1 - k));
//   }
//   return ema;
// };

// const hslToHex = (h, s, l) => {
//   h /= 360;
//   s /= 100;
//   l /= 100;
//   let r;
//   let g;
//   let b;
//   if (s === 0) {
//     r = g = b = l; // achromatic
//   } else {
//     const hue2rgb = (p, q, t) => {
//       if (t < 0) t += 1;
//       if (t > 1) t -= 1;
//       if (t < 1 / 6) return p + (q - p) * 6 * t;
//       if (t < 1 / 2) return q;
//       if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
//       return p;
//     };
//     const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
//     const p = 2 * l - q;
//     r = hue2rgb(p, q, h + 1 / 3);
//     g = hue2rgb(p, q, h);
//     b = hue2rgb(p, q, h - 1 / 3);
//   }
//   const toHex = x => {
//     const hex = Math.round(x * 255).toString(16);
//     return hex.length === 1 ? '0' + hex : hex;
//   };
//   return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
// };

// const percentageToHsl = (percentage, hue0, hue1) => {
//   const hue = percentage * (hue1 - hue0) + hue0;

//   const Lfloor = 20;
//   const Lceiling = 50;
//   const Ldelta = (Lceiling - Lfloor) / Lfloor;
//   const l = Lfloor + Lfloor * Ldelta * percentage;

//   return hslToHex(hue, 100, l);
// };

// export const processMapChartData = data => {
//   let totalIndex = null;
//   const result = {};

//   data.columns.forEach((col, index) => {
//     if (col[0] === 'AVG ISSUES PER SITE' && col[1] === 'Total') {
//       totalIndex = index;
//     }
//   });

//   data.index.forEach((val, index) => {
//     if (val !== 'Total') {
//       const record = val;
//       result[record] = {
//         id: record,
//         state: val,
//         abbr: record,
//         data: [],
//         regression: null,
//         points: [],
//         color: null,
//         month: []
//       };

//       data.data[index].forEach((value, key) => {
//         if (key >= 0 && key < totalIndex) {
//           const count = result[record].data.length;
//           result[record].data.push([count, value]);
//           const currentDate = data.columns[key][1].split('-');
//           result[record].month.push(`${currentDate[0]}/${currentDate[1]}`);
//         }
//       });
//     }
//   });

//   Object.entries(result).forEach(([key, value]) => {
//     const thisRegression = regression.linear(value.data);
//     result[key].regression = thisRegression.equation;

//     thisRegression.points.forEach((newValue, newKey) => {
//       result[key].points.push(newValue[1]);
//     });

//     const slope = thisRegression.equation[0];

//     const maxValue = Math.max.apply(null, result[key].points);
//     const ema = ExponentialMovingAvg(result[key].points, result[key].points.length);
//     const minEma = Math.min.apply(null, ema);

//     const pctDiff = minEma / maxValue;
//     let color;

//     if (slope >= 0) {
//       color = percentageToHsl(pctDiff, 0, 60);
//     } else if (slope < 0) {
//       color = percentageToHsl(pctDiff, 60, 120);
//     }
//     result[key].color = color;
//   });

//   return result;
// };

// export const getRelatedTests = (row, data, hideCompleted) => {
//   // WIP
//   const relatedTests = [];
//   const siteId = row.site.id;
//   const expandedArray = Object.keys(data).map(key => data[key]);
//   expandedArray.forEach(site => {
//     if ((hideCompleted && site.completion_date) || site.site.id === siteId) {
//       relatedTests.push(site);
//     }
//   });
//   return relatedTests;
// };

// export const processLineItemsDataToExport = (data, headers, formatDate) => {
//   const newHeaders = headers
//     .filter(h => h.title !== '')
//     .map(h => {
//       return { lable: h.title, key: h.accessor };
//     });
//   const newData = data.map(d => {
//     return {
//       ...d,
//       due_date: formatDate(d.due_date),
//       created_on: formatDate(d.created_on)
//     };
//   });
//   return { newData, newHeaders };
// };

// export const processTestsDataToExport = (data, headers) => {
//   const newHeaders = headers
//     .filter(h => h.title !== '')
//     .map(h => {
//       return { lable: h.name, key: h.accessor };
//     });
//   const newData = data.map(d => {
//     return {
//       ...d,
//       planned_date: d.planned_date,
//       next_planned_date: d.next_planned_date,
//       completion_date: d.completion_date,
//       assignees: d.assignees.map(x => `${x.first_name} ${x.last_name}`).join(', ')
//     };
//   });
//   return { newData, newHeaders };
// };

// export const processAnyDataToExport = (data, headers) => {
//   const newHeaders = headers
//     .filter(h => h.title !== '')
//     .map(h => {
//       return { lable: h.name, key: h.accessor };
//     });
//   return { newData: data, newHeaders };
// };

// export const processPermitsDataToExport = (data, headers) => {
//   const newHeaders = headers
//     .filter(h => h.title !== '')
//     .map(h => {
//       return { lable: h.name, key: h.accessor };
//     });
//   const newData = data.map(d => {
//     return {
//       ...d,
//       due_date: d.due_date,
//       issue_date: d.issue_date,
//       agency_label: d.agency.name,
//       assignees: d.assignees.map(x => `${x.first_name} ${x.last_name}`).join(', ')
//     };
//   });
//   return { newData, newHeaders };
// };

// export const processEmailStringToArray = emailString => {
//   const emailArray = emailString
//     .trim()
//     .replace(/ /g, '')
//     .replace(/(\r\n|\n|\r)/gm, '')
//     .split(',')
//     .filter(e => e !== '');
//   return emailArray;
// };

// export const getUnique = (arr, comp) => {
//   const unique = arr
//     .map(e => e[comp])
//     .map((e, i, final) => final.indexOf(e) === i && i)
//     .filter(e => arr[e]).map(e => arr[e]);

//   return unique;
// };

export const loadingTime = (miliSec, setLoadingValueFnc) => {
  const time = () => new Promise(resolve => setTimeout(resolve, miliSec));
  time().then(() => setLoadingValueFnc(false));
};

export const processCsvFromFileResponse = res => {
  let data;
  let file;
  const fullType = res.headers['content-type'].split(';')[0];
  if (typeof URL.createObjectURL === 'undefined') {
    data = `data:${fullType};charset=utf-8,${res.data}`;
  } else {
    const URL = window.URL || window.webkitURL;
    file = new Blob([res.data], { type: fullType });
    data = URL.createObjectURL(file);
  }
  return data;
};

export const processXlsxFromFileResponse = res => {
  let data;
  let file;
  const test = read(res.data, { type: 'array' });
  Object.keys(test.Sheets).map(
    // eslint-disable-next-line
    s =>
      (test.Sheets[s]['!cols'] = [
        { wch: 15 },
        { wch: 35 },
        { wch: 15 },
        { wch: 25 },
        { wch: 30 },
        { wch: 100 }
      ])
  );
  const workbook = write(test, { bookType: 'xlsx', type: 'array', compression: true });
  if (typeof URL.createObjectURL === 'undefined') {
    data = `${workbook}`;
  } else {
    const URL = window.URL || window.webkitURL;
    file = new Blob([workbook], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    });
    data = URL.createObjectURL(file);
  }
  return data;
};

export const processHtmlFromFileResponse = res => {
  let data;
  let file;
  const fullType = res.headers['content-type'].split(';')[0];
  if (typeof URL.createObjectURL === 'undefined') {
    data = res.data;
  } else {
    const URL = window.URL || window.webkitURL;
    file = new Blob([res.data], { type: fullType });
    data = URL.createObjectURL(file);
  }
  return data;
};

export const excludedRoutes = [
  'dev',
  'share',
  'profile',
  'settings',
  'logout',
  'facility_profile',
  'show_image',
  'show_pdf',
  'issues'
];

export const publicRoutes = ['forgot_password', 'reset_password', 'login', 'share', 'register'];

export const admins = ['Owner', 'Developer', 'Manager', 'Admin'];
export const outUsers = ['Viewer', 'Contractor'];
export const users = ['Member', 'Inspector'];
export const errorPages = ['404'];
export const allRoles = [...outUsers, ...users, ...admins];

const AllRoles = {
  Owner: [
    'atg',
    'analytics_enabled',
    'file_cabinet_enabled',
    'permit_tracking_enabled',
    'hot_topics_enabled',
    'workorders_enabled',
    'site_profile_enabled',
    'test_tracking_enabled',
    'reports_enabled',
    'contractor_management_enabled',
    'issue_tracker_enabled',
    'agency_management_enabled',
    'dashboard',
    'facility_profile',
    'scorecard_enabled',
    'daily_overview_enabled'
  ],
  Developer: [
    'atg',
    'analytics_enabled',
    'file_cabinet_enabled',
    'permit_tracking_enabled',
    'hot_topics_enabled',
    'workorders_enabled',
    'site_profile_enabled',
    'test_tracking_enabled',
    'reports_enabled',
    'contractor_management_enabled',
    'issue_tracker_enabled',
    'agency_management_enabled',
    'dashboard',
    'facility_profile',
    'scorecard_enabled',
    'daily_overview_enabled'
  ],
  Manager: [
    'atg',
    'analytics_enabled',
    'file_cabinet_enabled',
    'permit_tracking_enabled',
    'hot_topics_enabled',
    'workorders_enabled',
    'site_profile_enabled',
    'test_tracking_enabled',
    'reports_enabled',
    'contractor_management_enabled',
    'issue_tracker_enabled',
    'agency_management_enabled',
    'dashboard',
    'facility_profile',
    'scorecard_enabled',
    'daily_overview_enabled'
  ],
  Admin: [
    'atg',
    'analytics_enabled',
    'file_cabinet_enabled',
    'permit_tracking_enabled',
    'hot_topics_enabled',
    'workorders_enabled',
    'site_profile_enabled',
    'test_tracking_enabled',
    'reports_enabled',
    'contractor_management_enabled',
    'issue_tracker_enabled',
    'agency_management_enabled',
    'dashboard',
    'facility_profile',
    'scorecard_enabled'
  ],
  Member: [
    'atg',
    'analytics_enabled',
    'file_cabinet_enabled',
    'permit_tracking_enabled',
    'hot_topics_enabled',
    'workorders_enabled',
    'site_profile_enabled',
    'test_tracking_enabled',
    'reports_enabled',
    'contractor_management_enabled',
    'issue_tracker_enabled',
    'agency_management_enabled',
    'dashboard',
    'facility_profile',
    'scorecard_enabled'
  ],
  Inspector: [
    'atg',
    'analytics_enabled',
    'file_cabinet_enabled',
    'permit_tracking_enabled',
    'hot_topics_enabled',
    'workorders_enabled',
    'site_profile_enabled',
    'test_tracking_enabled',
    'reports_enabled',
    'contractor_management_enabled',
    'issue_tracker_enabled',
    'agency_management_enabled',
    'dashboard',
    'facility_profile'
  ],
  Viewer: ['file_cabinet_enabled'],
  Contractor: ['issue_tracker_enabled', 'workorders_enabled']
};
const getRoutes = roles => {
  let routes = [];
  roles.forEach(r => {
    if (r in AllRoles) {
      routes = [...AllRoles[r], ...routes];
    }
  });

  return [...routes, ...errorPages];
};

export const checkPerms = (name, currentUser = null) => {
  const user = currentUser || getUserData();
  if (user.logged_in) {
    const { roles } = user;
    const userRoutes = getRoutes(roles);
    return userRoutes.includes(name) || excludedRoutes.includes(name);
  }
  return publicRoutes.includes(name);
};

export const checkFeatures = name => getUserData()?.features?.includes(name);

export const overridePosition = obj => {
  return { left: obj.left + 60, top: obj.top - 20 };
};

export const hideAdmin = user => {
  // eslint-disable-next-line no-restricted-syntax
  if (!Array.isArray(user.roles)) {
    return true;
  }
  for (const r of user.roles) {
    if (admins.includes(r)) {
      return false;
    }
  }
  return true;
};

export const canSeeMobileApp = user => {
  const canSeeMobile = ['Member', 'Inspector', 'Owner', 'Developer', 'Manager'];
  const userRoles = user.roles;
  // eslint-disable-next-line no-restricted-syntax
  for (const r of userRoles) {
    if (canSeeMobile.includes(r)) {
      return true;
    }
  }
  return false;
};

export const canSeeMyWO = () => {
  const user = getUserData();
  return user?.features?.includes('workorder_tracking_page.my_workorder_tab');
};

export const isContractor = () => {
  // so stupid.. I know..
  const user = getUserData();
  if (user.roles.join(',') === 'Contractor') {
    return true;
  }
  if (
    user.roles.length === 2 &&
    user.roles.includes('Contractor') &&
    user.roles.includes('Viewer')
  ) {
    return true;
  }
  return false;
};

export const getFirstEntry = () => {
  const user = getUserData();
  if (!user.logged_in) return '/login';
  // eslint-disable-next-line no-restricted-syntax
  for (const r of user.roles) {
    if ([...users, ...admins].includes(r)) {
      return '/workorders';
    }
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const r of user.roles) {
    if (r === 'Contractor') {
      return '/workorders';
    }
  }
  return '/e-cabinet';
};

// const leftSubTypes = {
//   prodSpec: item => [
//     {
//       name: 'Product Line',
//       value: (item && item.number) || ''
//     },
//     {
//       name: 'Delivery Type',
//       value: (item && item.delivery_type) || ''
//     },
//     {
//       name: 'Line Install Date',
//       value: (item && item.installation_date) || ''
//     }
//   ],
//   prodPip: item => [
//     {
//       name: 'Product Line',
//       value: (item && item.product_line) || ''
//     },
//     {
//       name: 'Stp Containment',
//       value: (item && item.stp_containment) || ''
//     },
//     {
//       name: 'Stp Sump Sensor',
//       value: (item && item.stp_sump_sensor && 'Y') || 'N'
//     }
//   ],
//   spill: item => [
//     {
//       name: 'Fill Spill  Bucket',
//       value: (item && item.fill_spill_bucket) || ''
//     },
//     {
//       name: 'Bucket Install Date',
//       value: (item && item.bucket_install_date) || ''
//     },
//     {
//       name: 'Double Wall Spill Bucket',
//       value: item && item.double_wall_spill_bucket ? 'Y' : 'N'
//     }
//   ],
//   recovery: item => [
//     {
//       name: 'Stage 1 Vapor Recovery',
//       value: (item && item.stage_1_vapor_recovery) || ''
//     }
//   ]
// };

// const rightSubTypes = {
//   prodSpec: item => [
//     {
//       name: 'Line Leak Detection Type (.2 GPH and/or .1 GPH)',
//       value: (item && item.number) || ''
//     },
//     {
//       name: 'Line Leak Detector Type (3 Gph)',
//       value: (item && item.leak_detection_type) || ''
//     },
//     {
//       name: 'Wall Type',
//       value: (item && item.wall_type) || ''
//     }
//   ],
//   prodPip: item => [
//     {
//       name: 'Sump Install Date',
//       value: (item && item.installation_date) || ''
//     },
//     {
//       name: 'Sump Type',
//       value: (item && item.sump_type) || ''
//     }
//   ],
//   spill: item => [
//     {
//       name: 'Sensor Installed',
//       value: item && item.sensor_installed ? 'Y' : 'N'
//     },
//     {
//       name: 'Spill Bucket Type',
//       value: (item && item.spill_bucket_type) || ''
//     }
//   ],
//   recovery: item => [
//     {
//       name: 'Stage 1 Type',
//       value: (item && item.stage_1_type) || ''
//     }
//   ]
// };

// const generateLeftWithSubLists = (type, data, key) => {
//   if (!key) {
//     return leftSubTypes[type](data);
//   }
//   return leftSubTypes[type](data[key]);
// };

// const generateRightWithSubLists = (type, data, key) => {
//   if (!key) {
//     return rightSubTypes[type](data);
//   }
//   return rightSubTypes[type](data[key]);
// };

// const resolveVaporRecovery = data => {
//   const recovery = [];
//   if (!data.length) {
//     return [];
//   }
//   data.forEach(vr => {
//     recovery.push({
//       name: 'Vapor Recovery',
//       value: (vr && vr.stage_2_vapor_recovery) || ''
//     });
//     recovery.push({
//       hide: true,
//       name: 'Vapor Recovery Type',
//       value: (vr && vr.stage_2_type) || ''
//     });
//   });
//   return recovery;
// };

// const resolveDispenser = (disp, idx, pIdx) => {
//   const vaporRecovery = resolveVaporRecovery(disp.vapor_recovery);
//   const subTab = {
//     name: `Dispenser ${disp.number}`,
//     uniqueName: `p-${pIdx}-d-${disp.number}`,
//     active: idx === 0 && pIdx === 0,
//     details: [
//       {
//         left: [
//           {
//             name: 'Dispenser Connection Corrosion Protection',
//             value: (disp && disp.dispenser_connection_corrosion_protection) || ''
//           },
//           {
//             name: 'Sump Install Date 1',
//             value: (disp && disp.sump_install_date_1) || ''
//           },
//           {
//             name: 'Udc Present',
//             value: disp && disp.udc_present ? 'Y' : 'N'
//           }
//         ],
//         right: [
//           {
//             name: 'Udc Sensor Present',
//             value: disp && disp.udc_sensor_present ? 'Y' : 'N'
//           },
//           ...vaporRecovery
//         ]
//       }
//     ]
//   };
//   return subTab;
// };

// const resolveProduct = (product, idx) => {
//   const tab = {
//     name: `Product line ${product.specification.number}`,
//     active: idx === 0,
//     subTabs: product.dispensers.map((d, dIdx) => resolveDispenser(d, dIdx, idx))
//   };
//   return tab;
// };

// const FSTypes = {
//   spec: data => ({
//     left: [
//       {
//         name: 'Tank',
//         value: data ? data.tank_number : ''
//       },
//       {
//         name: 'Tank ID',
//         value: data ? data.tank_id : ''
//       },
//       {
//         name: 'Product',
//         value: data ? data.product : ''
//       },
//       {
//         name: 'Diameter (in)',
//         value: data ? data.diameter : ''
//       },
//       {
//         name: 'Actual Gallons Capacity',
//         value: data ? data.capacity : ''
//       },
//       {
//         name: 'AST / UST',
//         value: data ? data.storage : ''
//       }
//     ],
//     right: [
//       {
//         name: 'Interstice',
//         value: data ? data.interstice : ''
//       },
//       {
//         name: 'Product Category',
//         value: data ? data.product_category : ''
//       },
//       {
//         name: 'Product Name',
//         value: data ? data.product_name : ''
//       },
//       {
//         name: 'Tank Install Date',
//         value: (data && data.installation_date) || ''
//       },
//       {
//         name: 'Tank Wall',
//         value: data ? data.wall : ''
//       }
//     ]
//   }),
//   leak: data => {
//     const resolveLeak = item => ({
//       left: [
//         {
//           name: 'Tank Leak Detection',
//           value: (item && item.number) || ''
//         },
//         {
//           name: 'Tank Leak Detection Type',
//           value: (item && item.type) || ''
//         }
//       ],
//       right: []
//     });
//     return data.map(ld => resolveLeak(ld));
//   },
//   atg: data => {
//     const resolveATG = item => ({
//       left: [
//         {
//           name: 'Leak Detection Monitor',
//           value: (item && item.number) || ''
//         },
//         {
//           name: 'Monitor manufacturer',
//           value: (item && item.manufacturer) || ''
//         },
//         {
//           name: 'Monitor Model',
//           value: (item && item.model) || ''
//         }
//       ],
//       right: []
//     });
//     return data.map(atg => resolveATG(atg));
//   },
//   prodSpec: data => {
//     if (data.length === 1) {
//       return {
//         left: generateLeftWithSubLists('prodSpec', data[0], 'specification'),
//         right: generateRightWithSubLists('prodSpec', data[0], 'specification')
//       };
//     }
//     if (data.length === 0) {
//       return null;
//     }
//     const chanks = [];
//     data.forEach(c => {
//       const arr = {
//         left: generateLeftWithSubLists('prodSpec', c, 'specification'),
//         right: generateRightWithSubLists('prodSpec', c, 'specification')
//       };
//       chanks.push(arr);
//     });
//     return chanks;
//   },
//   prodPip: data => {
//     if (data.length === 0) {
//       return null;
//     }
//     const items = data.map(i => [i.piping_sumps, i.specification.number]);
//     const chanks = [];
//     items.forEach(pl => {
//       pl[0].forEach(ps => {
//         const left = generateLeftWithSubLists('prodPip', ps);
//         left[left.findIndex(x => x.name === 'Product Line')] = {
//           name: 'Product Line',
//           value: pl[1]
//         };
//         const arr = {
//           left,
//           right: generateRightWithSubLists('prodPip', ps)
//         };
//         chanks.push(arr);
//       });
//     });
//     return chanks;
//   },
//   spill: data => {
//     if (data.length === 0) {
//       return null;
//     }
//     const chanks = [];
//     data.forEach(c => {
//       const arr = {
//         left: generateLeftWithSubLists('spill', c),
//         right: generateRightWithSubLists('spill', c)
//       };
//       chanks.push(arr);
//     });
//     return chanks;
//   },
//   recovery: data => {
//     if (data.length === 0) {
//       return null;
//     }
//     const chanks = [];
//     data.forEach(c => {
//       const arr = {
//         left: generateLeftWithSubLists('recovery', c),
//         right: generateRightWithSubLists('recovery', c)
//       };
//       chanks.push(arr);
//     });
//     return chanks;
//   },
//   disp: data => {
//     return data.map(resolveProduct);
//   }
// };

// export const processItems = ({ type, data }) => {
//   if (!type) return { left: [], right: [] };
//   return FSTypes[type](data);
// };

// const initWeekBase = () => {
//   const week = {};
//   moment.updateLocale('en', {
//     week: {
//       dow: 1 // Monday is the first day of the week.
//     }
//   });
//   const day = moment();
//   // const day = moment(new Date('Thu Jul 03 2019 12:37:58 GMT+0300 (Eastern European Summer Time)')); // local
//   Array(...Array(7)).forEach((_, i) => {
//     week[
//       day
//         .startOf('week')
//         .weekday(i)
//         .format('ddd-D')
//     ] = [];
//   });
//   return week;
// };

// const checkEventTypes = (facility, day) => {
//   const types = {};
//   Object.entries(day).forEach(([k, v]) => {
//     if (v.includes(facility)) {
//       types[k] = true;
//     }
//   });
//   return types;
// };

// const processDayValues = day => {
//   const facilities = [...new Set(Object.values(day).flat(1))];
//   const newDay = [];
//   facilities.forEach(f =>
//     newDay.push({
//       name: f,
//       types: checkEventTypes(f, day)
//     })
//   );
//   return newDay;
// };

// export const initCalendar = async data => {
//   const baseWeek = initWeekBase();
//   const lastDaysArr = Object.entries(baseWeek).slice(5);
//   const lastDaysTitle = lastDaysArr
//     .map(x => {
//       delete baseWeek[x[0]];
//       return x[0];
//     })
//     .join(' / ');
//   baseWeek[lastDaysTitle] = [];
//   const weekTitles = Object.keys(baseWeek);
//   Object.entries(data).forEach(([k, v]) => {
//     const weekDate = moment(k, 'YYYY/MM/DD').format('ddd-D');
//     const day = weekTitles.find(n => n.includes(weekDate));
//     baseWeek[day] = processDayValues(v);
//   });
//   return baseWeek;
// };

export const imageToB64 = async url => {
  try {
    const res = await axios.get(url, { responseType: 'arraybuffer' });
    const image = btoa(
      new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
    );
    return `data:${res.headers['content-type'].toLowerCase()};base64,${image}`;
  } catch (e) {
    return false;
  }
};

export const makeSortStr = sortBy => {
  const s = [];
  sortBy.forEach(x => {
    s.push(`${x.desc ? '-' : ''}${x.id}`);
  });
  return s.join(',');
};

export const makeFilterStr = filters => {
  const f = [];
  Object.entries(filters).forEach(([k, v]) => {
    if (v) {
      f.push(`filter[${k}]=${v}`);
    }
  });
  return f.join(',');
};

export const makeFetchFilterStr = filters => {
  const f = [];
  Object.entries(filters).forEach(([k, v]) => {
    if (v.length) {
      const val = k !== 'date_range' ? `&${k}=${v.map(x => x.id).join(',')}` : `&${k}=${v}`;
      f.push(val);
    }
  });
  return f.join('');
};

export const processExportFile = {
  xlsx: processXlsxFromFileResponse,
  html: processHtmlFromFileResponse,
  csv: processCsvFromFileResponse
};

export const formatDate = (date, apiDateFormat, displayDateFormat) => {
  if (!date) {
    return '-';
  }
  return moment(date, apiDateFormat).format(displayDateFormat);
};

export const checkFeatureToggles = name => {
  const userData = getUserData();
  return userData && userData.feature_toggles && userData.feature_toggles[name];
};
